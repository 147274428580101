import React, { useState, useRef, useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const ColumnsMobile = props => {
  const [columnState, setColumnState] = useState(
    props.data.map((item) => {
      return {
        title: item.primary.label.text,
        items: item.items,
        isActive: false,
      }
    })
  )

  const handleToggle = title => {
    setColumnState(
      columnState.map((item) => {
        return {
          title: item.title,
          items: item.items,
          isActive:
            item.title === title && item.isActive === false ? true : false,
        }
      })
    )
  }

  // Set ref to get the height of each accordion
  const [height, setHeight] = useState(0)
  const ref = useRef()

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (!ref.current) return
      const { height } = ref.current.getBoundingClientRect()
      setHeight(height)
    }

    updateHeight()

    window.addEventListener("resize", updateHeight)
    return () => {
      window.removeEventListener("resize", updateHeight)
    }
  })

  return (
    <>
      {columnState.map((column, index) => {
        // const itemCount = column.items.length;
        // const itemHeight = `max-h-[${itemCount * 32}px]`;

        const columnItems = column.items.map((columnItem, indexItem) => (
          <Link
            key={indexItem}
            to={`${columnItem.target}`}
            className={`text-xs font-light flex items-center transition ease-in-out my-3 ${
              column.isActive
                ? `opacity-100 duration-1000`
                : `opacity-0 duration-1000`
            }`}
            style={{
              transitionDelay: column.isActive ? indexItem + 2 + "00ms" : "0ms",
            }}
          >
            {columnItem.label.text}
          </Link>
        ))

        return (
          <div
            className={`flex-1 lg:hidden border-b border-white ${
              index === 0 ? "border-t" : ""
            }`}
            key={index}
          >
            <div
              className={`w-full relative pt-3 cursor-pointer focus:outline-none flex justify-between items-center`}
              role="button"
              tabIndex={0}
              onClick={() => handleToggle(column.title)}
              onKeyDown={() => handleToggle(column.title)}
            >
              <h4 className="uppercase text-xs tracking-widest font-bold inline-block align-middle">
                {column.title}
              </h4>
              <i
                className={`fal ${
                  column.isActive ? "fa-minus" : "fa-plus"
                } text-sm float-right align-middle`}
                aria-hidden
              ></i>
            </div>
            <div
              className={`w-full overflow-hidden transition-all duration-1000 ease-in-out pb-3 transform origin-top`}
              style={{ maxHeight: column.isActive ? height + 50 : 0 }}
            >
              <div ref={ref} className="footer-mobile-links">
                {columnItems}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

const Footer = () => {
  const query = useStaticQuery(graphql`
    query FooterQuery {
      prismicFooterNavigation {
        data {
          body {
            ... on PrismicFooterNavigationDataBodyColumn {
              items {
                label {
                  text
                }
                target
              }
              primary {
                label {
                  text
                }
                target
              }
              slice_type
            }
          }
        }
      }
    }
  `)

  function toTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const columns = query.prismicFooterNavigation.data.body.map(
    (column, index) => {
      const columnItems = column.items.map((columnItem, indexItem) => (
        <Link
          key={indexItem}
          to={`${columnItem.target}`}
          className="text-xs7 font-light block py-2 footer-hover"
        >
          {columnItem.label.text}
        </Link>
      ))
      return (
        <div className="flex-1 hidden lg:block" key={index}>
          <span className="uppercase text-xs7 tracking-widest font-bold block mb-3">
            {column.primary.label.text}
          </span>
          {columnItems}
        </div>
      )
    }
  )

  return (
    <React.Fragment>
      <footer className="bg-darkgrey text-white">
        <button
          className="bg-black hover:bg-gold duration-500 w-full text-center uppercase text-tiny tracking-widest font-light p-2 focus:outline-none"
          onClick={toTop}
        >
          Back to top
        </button>
        <div className="container py-0">
          <div className="columns-container py-8">
            <div className="flex flex-col lg:flex-row">
              {columns}
              <ColumnsMobile data={query.prismicFooterNavigation.data.body} />
              <div className="flex-1 lg:mt-0 mt-6">
                <h4 className="uppercase text-xs7 tracking-widest font-bold">
                  Contact Us
                </h4>
                <p className="text-xs7 font-light my-5">
                  Deuren, <br />
                  Unit H, Wheatley Park, <br />
                  Woodbottom, <br />
                  Mirfield, WF14 8HE <br />
                </p>
                <a
                  href="tel:+448001386688"
                  className="text-xs font-semibold block"
                >
                  T: <span className="footer-hover">+44 (0) 800 138 6688</span>
                </a>
                <a
                  href="mailto:info@deuren.co.uk"
                  className="text-xs font-semibold block"
                >
                  E: <span className="footer-hover">info@deuren.co.uk</span>
                </a>
              </div>
              <ul className="flex-none lg:w-1/5 w-full lg:text-right lg:mt-0 mt-10 grid grid-cols-2 grid-rows-2 items-center justify-between">
                <li className="flex justify-center">
                  <a
                    href="https://www.madeinbritain.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://deuren.cdn.prismic.io/deuren/dcb0a6eb-74d9-4320-82df-9e5306555746_mib.svg"
                      alt="Made in Britain"
                      width="51"
                      height="73"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li className="flex justify-center">
                  <img
                    src="https://deuren.cdn.prismic.io/deuren/a3005a1e-d350-430a-90c5-2f7457958d31_iso.svg"
                    alt="ISO Logo"
                    width="90"
                    height="73"
                    loading="lazy"
                  />
                </li>
                <li className="flex justify-center">
                  <img
                    src="https://images.prismic.io/deuren/ZjEffUMTzAJOCc-V_NQAISO9001Logo-UKAS.jpg?auto=format,compress"
                    alt="UKAS Logo"
                    width="97"
                    height="73"
                    loading="lazy"
                  />
                </li> 
                <li className="flex justify-center">
                  <a
                    href="https://www.deuren.co.uk/our-blog/premium-partner-grand-designs-magazine/"
                    target="_blank"
                    rel="noreferrer"

                  >
                    <img
                      src="https://images.prismic.io/deuren/Z7dWhp7c43Q3gBw7_GD-MAGAZINE_BAR_APPROVED-PREMIUM-PARTNER_RGB.png?auto=format,compress"
                      width="100"
                      height="100"
                      loading="lazy" 
                      alt="Grand Designs Magazine"
                    />
                  </a>
                </li>                               
              </ul>
            </div>
          </div>
          <hr className="h-px border-gold opacity-50 w-full" />
          <div className="flex flex-col lg:flex-row justify-between items-center py-8">
            <div className="flex-1 w-full">
              <Link
                to="/cookie-policy/"
                className="font-semibold text-xxs mr-6 lg:inline block mb-2 hover:text-gold duration-500"
              >
                Cookie Policy
              </Link>
              <Link
                to="/privacy-policy/"
                className="font-semibold text-xxs mr-6 lg:inline block mb-2 hover:text-gold duration-500"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-and-conditions/"
                className="font-semibold text-xxs lg:inline block mb-2 hover:text-gold duration-500"
              >
                Terms &amp; Conditions
              </Link>
            </div>
            <div className="flex-1 w-full flex lg:justify-center justify-start lg:my-0 my-6">
              <a
                href="https://www.facebook.com/deurenuk/"
                target="_blank"
                rel="noreferrer"
                className="mr-5 text-xl footer-hover"
              >
                <i
                  className="fab fa-facebook"
                  aria-label="Facebook"
                  aria-hidden="true"
                ></i>{" "}
              </a>
              <a
                href="https://www.instagram.com/deurendoors/"
                target="_blank"
                rel="noreferrer"
                className="mr-5 text-xl footer-hover"
              >
                <i
                  className="fab fa-instagram"
                  aria-label="Instagram"
                  aria-hidden="true"
                ></i>{" "}
              </a>
              <a
                href="https://twitter.com/DeurenDoors?lang=en-gb"
                target="_blank"
                rel="noreferrer"
                className="mr-5 text-xl footer-hover"
              >
                <i
                  className="fab fa-twitter"
                  aria-label="Twitter"
                  aria-hidden="true"
                ></i>{" "}
              </a>
              <a
                href="https://www.pinterest.co.uk/deurendoors/"
                target="_blank"
                rel="noreferrer"
                className="mr-5 text-xl footer-hover"
              >
                <i
                  className="fab fa-pinterest"
                  aria-label="Pinterest"
                  aria-hidden="true"
                ></i>{" "}
              </a>
              <a
                href="https://www.houzz.co.uk/pro/deuren/deuren"
                target="_blank"
                rel="noreferrer"
                className="text-xl footer-hover"
              >
                <i
                  className="fab fa-houzz"
                  aria-label="Houzz"
                  aria-hidden="true"
                ></i>{" "}
              </a>
            </div>
            <div className="flex-1 w-full flex lg:justify-end justify-start">
              <span className="text-xxs font-light">
                &copy; Deuren Ltd 2020 &nbsp; | &nbsp; Website by{" "}
                <a
                  href="https://www.thebiggerboat.co.uk/"
                  className="font-bold footer-hover"
                >
                  The Bigger Boat
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
